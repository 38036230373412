import { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import "./graphicalDataUsage.css"
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button
} from "@mui/material";
import { updatePermission } from "../../actions/Users/authenticate";
import { getLteProviders, getGraphicalDataUsage, GetDataUsageType, QueryParameters, getUsageVesselList, SIM_TYPE } from "../../actions/Users/authenticateReports";
import {  getDateForChart, getDecodeURI, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../utils/util"
import ChartSelected from "../../asset/image/ChartSelected.svg"
import ChartUnSelected from "../../asset/image/ChartUnSelected.svg"
import { getEncodedURI } from '../../utils/util';
import CustomLoader from "../../components/Loader/CustomLoader";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import AppUsageCard from "../../components/AppUsageCard";
import SingleSiteNetworkUsage from "../../components/AppUsageCard/singleSiteNetworkUsage";
import SingleSiteAllInterfaceUsage from "../../components/AppUsageCard/singleSiteAllInterfaceUsage";
import SingleSiteVsatNetworkUsage from "../../components/AppUsageCard/singleSiteVsatSelectionNetworkUsage";
import SingleSiteWanNetworkUsage from "../../components/AppUsageCard/singleSiteWanSelectionNetworkUsage";
import SingleSiteInterfaceUsage from "../../components/AppUsageCard/singleSiteInterfaceUsage";
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, {clearCsvDownload} from "../../components/DownloadCSV";
import {getWanUsageSingleSiteNetworkUsage, getWanUsageSingleSiteWanSelectionNetworkUsage, getWanUsageSingleSiteVsatSelectionNetworkUsage} from '../../actions/Users/authenticateReports';

interface CSVTable {
  columns: string[];
  rows: any[][];
}

interface DataUsageFilter {
  type: string;
  interval: string,
  startDate: string,
  endDate: string,
  tablesize: string
}

const createRows = (data: CSVTable, defaultValue: any[] = []) => {
  if (!data || typeof data.rows == 'undefined')
    return defaultValue;
  return data.rows.map(row => {
    const rowValue: { [key: string]: any } = {};
    data.columns.forEach((col, index) => {
      rowValue[col] = row[index];
    })
    return rowValue;
  })
}

const convertToCsv = (data: any[][] ) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
      csvRows.push([ row[0], readableBytesAsGB(row[1]).replace(" GB", ""), readableBytesAsGB(row[2]).replace(" GB", ""), readableBytesAsGB(row[3]).replace(" GB", ""), readableBytesAsGB(row[4]).replace(" GB", ""), readableBytesAsGB(row[5]).replace(" GB", ""), readableBytesAsGB(row[6]).replace(" GB", ""), readableBytesAsGB(row[7]).replace(" GB", ""), readableBytesAsGB(row[8]).replace(" GB", ""), readableBytesAsGB(row[9]).replace(" GB", ""), readableBytesAsGB(row[10]).replace(" GB", ""), readableBytesAsGB(row[11]).replace(" GB", ""), readableBytesAsGB(row[12]).replace(" GB", "")]);
  })
  return [['Time', 'TOTAL (GB)', 'CELLULAR (GB)', 'LBAND (GB)', 'WIFI (GB)', 'ETHERNET (GB)', 'STARLINK (GB)', 'VSAT (GB)', 'VSAT_HIGHSPEED  (GB)', 'VSAT_KA  (GB)', 'VSAT_KU  (GB)', 'VSAT_UNLIMITED  (GB)', 'VSAT_VOIP  (GB)'], ...csvRows]
}

const sizeConversion = (value: number) => {
  let _bytes = value;
  if (_bytes <= 0) {
    return "0 GB";
  }
  let i: number = Math.floor(Math.log(_bytes) / Math.log(1024));
  let sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return (_bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

const GraphicalDataAnalysis = (props) => {
  const { authReducer, getGraphicalDataUsage, getVesselsListing, filteredVessels, newSummaryStartDate, newSummaryEndDate, location, history, getWanUsageSingleSiteNetworkUsage, getWanUsageSingleSiteWanSelectionNetworkUsage, getWanUsageSingleSiteVsatSelectionNetworkUsage, wanUsageReportSingleSiteNetworkUsage, WanUsageSingleSiteWanSelectionNetworkUsage, WanUsageSingleSiteVsatSelectionNetworkUsage } = props;
  
  
  const _q = getDecodeURI(location?.search);
  const [selectCard, setSelectCard] = useState(_q.type ?? "TOTAL");
  const [dataUnit, setDataUnit] = useState("GB");
  const getInterval = (minutes) => {
    if (minutes <= 1440) {
      return "10m";
    } else if (minutes > 1440 && minutes <= 10080) {
      return "1h";
    } else {
      return "4h";
    }
  }
  const [counterCards, setcounterCards] = useState<any[]>([
    { label: 'TOTAL', value: 0},
    { label: 'VSAT', value: 0 },
    { label: 'CELLULAR', value: 0 },
    { label: 'STARLINK', value: 0 },
    { label: 'LBAND', value: 0},
    { label: 'ETHERNET', value: 0},
    { label: 'WIFI', value: 0}
  ]);
  const [dateWiseUsageData, setDateWiseUsageData] = useState<any>({})
  const [vesselWiseUsageData, setVesselWiseUsageData] = useState<any>([])
  const [tableLimit, setTableLimit] = useState<any>(_q.hasOwnProperty('limit') ? _q.limit : 30);
  const [legends, setLegends] = useState<any>([]);
  const [isChartDataAvailable, isSetChartDataAvailable] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: ""
  });
  const [vessels, setVessels] = useState<any>([]);
  const timeZone = getTimezoneCity(authReducer?.userTimezone);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!_.isEmpty(authReducer.getGraphicalDataUsage) && authReducer.getGraphicalDataUsage.data) {
      const {
        data: {
          operatorChart = {},
          sitewiseChart = {},
          totalTopUsage,
          operatorChartLegends = {}
        },
        type
      } = authReducer.getGraphicalDataUsage;

      if(operatorChartLegends) {
        const legends = operatorChartLegends?.rows?.map((item) => {
          return operatorChartLegends?.columns?.reduce((acc, columItem, i) => {
              acc[columItem] = item[i];
              return acc;
          }, {})
        })
        setLegends(legends);
      }

      if (sitewiseChart) {
        let usageArr: any = sitewiseChart?.rows?.map((ele) => {
          return {
            location_id: ele[0] ? ele[0] : '',
            application: ele[1] ? ele[1] : '',
            download: ele[3] ? ele[3] : '',
            percentage: ele[4] ? ele[4] : '',
            wan_usage: ele?.[2] ? ele?.[2]?.trim().split(",")?.map((ele) => {
              return { wan_type: `${ele.split('=')[0]}`, usage: Number(ele.split('=')[1]) }
            }) : []
          }
        })

        let limit = usageArr?.length;
        if (limit > 5) {
          limit = usageArr?.length / 3;
        }
        if (limit <= 5) {
          setVesselWiseUsageData([usageArr?.splice(0, 5), usageArr?.splice(0, 5), usageArr?.splice(0, 5)])
        } else {
          setVesselWiseUsageData([usageArr?.splice(0, Math.ceil(limit)), usageArr?.splice(0, Math.ceil(limit)), usageArr?.splice(0, Math.ceil(limit))])
        }
      }

      const [{ TOTAL, VSAT, CELLULAR, STARLINK, LBAND, ETHERNET, WIFI }] = createRows(totalTopUsage, [0]);
      setcounterCards([
        {
          label: 'TOTAL',
          value: readableBytesAsGB(TOTAL),
        },
        {
          label: 'VSAT',
          value: readableBytesAsGB(VSAT),
        },
        {
          label: 'CELLULAR',
          value: readableBytesAsGB(CELLULAR),
        },
        {
          label: 'STARLINK',
          value: readableBytesAsGB(STARLINK),
        },
        {
          label: 'LBAND',
          value: readableBytesAsGB(LBAND),
        },
        {
          label: 'ETHERNET',
          value: readableBytesAsGB(ETHERNET),
        },
        {
          label: 'WIFI',
          value: readableBytesAsGB(WIFI),
        }
      ])
      
      const vsatList: any = [];
      if(operatorChart) {
        const series = operatorChart?.wan_usage?.series || {};
        const seriesKeys = Object.keys(series);
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = seriesKeys.some(key => {
          const seriesValue = series[key];
          return (_.isArray(seriesValue) && seriesValue.some(item => item.y > gbValue))
        })
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
      
        let usage:any = seriesKeys.map((key, i) => {
            const colors = type == 'VSAT' ? ['#001a4d', '#3a88d1', '#4663f2', '#1f3da8', '#8ac9e0', '#c1d5f6'] : ['#001a4d', '#1f3da8', '#4663f2', '#3a88d1', '#8ac9e0', '#c1d5f6']
            const data = series[key]?.map((usage) => {
              if (usage.y != 0) {
                isSetChartDataAvailable(true);
              }
              return { x: usage.x, y: Number(convertBytes(usage.y, false)) }
            })
            return { name: key, data: data, marker:{symbol: 'circle'}, visible:true, color: colors[i] }
        });
        if(type == 'VSAT') {
          if(usage[0]) vsatList.push(usage[0]);
          if(usage[3]) vsatList.push(usage[3]);
          if(usage[2]) vsatList.push(usage[2]);
          if(usage[1]) vsatList.push(usage[1]);
          if(usage[4]) vsatList.push(usage[4]);
          if(usage[5]) vsatList.push(usage[5]);
        }
        setDataUnit(isGBCrossed ? 'GB' : 'MB')
        type == 'VSAT' ? setDateWiseUsageData(vsatList) : setDateWiseUsageData(usage);
      }

      authReducer.getGraphicalDataUsage = {};
    }
  }, [authReducer.getGraphicalDataUsage])

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    if (_.isEmpty(getVesselsListing))
      return;
    let vessels: any[] = [];
    if (!_.isEmpty(getVesselsListing)) {
      vessels = getVesselsListing?.locations;
    }
    if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
      vessels = filteredVessels;
    }
    setVessels(vessels);
  }, [getVesselsListing, filteredVessels]);

  useEffect(() => {
    const minutes = Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3);
    minutes !== 15 && processQueryUrl({});
  },[vessels, newSummaryStartDate, newSummaryEndDate])

  const processQueryUrl = (filters: Partial<DataUsageFilter>) => {
    const {
      type = selectCard,
      startDate = newSummaryStartDate,
      endDate = newSummaryEndDate,
      tablesize = tableLimit
    } = filters;

    const Interval = getInterval(Math.floor(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / 60e3));

    const parameters: Partial<QueryParameters> = {
      f1_k4id: `${vessels.map(v => `'${v.id}'`).join(',')}`,
      f6_wan_type: type=='VSAT' ? "vsat" : type=='CELLULAR' ? "lte" : type=='LBAND' ? "lband" : type=='ETHERNET' ? 'wired' : type=='WIFI' ? 'wifi' : "starlink",
      interval: Interval,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      tablesize: tablesize
    };
    if(vessels?.length > 0) {
      const _getGraphicalDataUsage: GetDataUsageType = getGraphicalDataUsage;
      _getGraphicalDataUsage({ type: type as SIM_TYPE, parameters });
    } else {
      setDateWiseUsageData([]);
      setVesselWiseUsageData([]);
      setcounterCards([{ label: 'TOTAL', value: 0}, { label: 'VSAT', value: 0 }, { label: 'CELLULAR', value: 0 }, { label: 'STARLINK', value: 0 }, { label: 'LBAND', value: 0}, { label: 'ETHERNET', value: 0}, { label: 'WIFI', value: 0}])
    }
    isSetChartDataAvailable(false);
  }

  const handleCardSelection = (label: string) => {
    if (authReducer.graphicalDataUsageLoading)
      return;
    setSelectCard(label);
    let params: any = getDecodeURI(location?.search);
    params.type = label;
    doNavigate(params);
    processQueryUrl({ type: label });
    isSetChartDataAvailable(false);
  }

  const options: any = { 
    time: {
      timezone: timeZone
    },
    chart: {
        type: 'column',
        height: '350px',
        plotBorderWidth: 1,
        resetZoomButton: {
          theme: {
              style: {
                display: 'none'
              }
          }
        },
        style: {
            fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
        },
      },
    title: {
        text: '',
        align: 'left',
        floating: true,
        x: 12,
        y: 32,
        style: {
            fontWeight: '500'
        }
    },
    lang: {
        noData: "No Data",
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        },
    },
    credits: {
        enabled: false
    },
    legend: {
       enabled: false
    },
    plotOptions: {
        series: {
            turboThreshold: 1000000,
            stickyTracking: false,
            connectNulls: false
        },
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        },
        colorByPoint: true
    },
    tooltip: {
        valueSuffix: ` ${dataUnit}`,
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        valueDecimals: 2,
        borderWidth: 1
    },
    xAxis: {
        gridLineWidth: 1,
        reversed: false,
        type: "datetime",
        maxPadding: 0.05,
        showLastLabel: true,
        labels: {
          format: getDateForChart(dateWiseUsageData) && getSingleRecordChartLable(dateWiseUsageData, timeZone)
        }
      },
    yAxis: {
        tickInterval: 0.5,
        gridLineWidth: 1,
        labels: {
          format: `{value} ${dataUnit}`,
        },
        title: {
            text: null
        }
    },
    series: dateWiseUsageData ? dateWiseUsageData : []
  }

  const handleLegendClick = (app, color, index) => {
    let all_true = true;
    let updatedData:any = [...dateWiseUsageData];
    let all_off_turn_on = 0;
    let click = 0;

    updatedData?.forEach(( ele, i)=>{
      if( !ele.visible ){
        all_true = false;
        all_off_turn_on +=1;
      }
      if(ele.visible){
        click = i;
      }
    })
    
    if(click == index && all_off_turn_on == updatedData.length-1 ){
        updatedData?.forEach((ele,i)=>{
        updatedData[i] = { ...ele, visible:true }
      })
      setDateWiseUsageData([...updatedData]);
    }
    else if(all_true){
      updatedData?.forEach((ele,i)=>{
      updatedData[i] = { ...ele, visible:false }
      })
      updatedData[index].visible = true;
      setDateWiseUsageData([...updatedData]);
    }
    else{
      updatedData.forEach((ele,i)=>{
        updatedData[i] = ele;
      })
      updatedData[index].visible = !updatedData[index].visible; 
      setDateWiseUsageData([...updatedData]);
    }
  }

  const handleChangeLimit = (e) => {
    const value = e.target.value;
    setTableLimit(value);
    let params: any = getDecodeURI(location?.search);
    params.limit = value;
    doNavigate(params);
    processQueryUrl({tablesize: value});
  }

  const handleDownloadCSV = () => {
    const Interval = getInterval(Math.floor(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
    let newInterval = Interval;
    if(Interval.includes('m')) {
        newInterval = Interval.replace("m", " minute")
    } else {
        newInterval = Interval.replace("h"," hour")
    }
    const params: CsvParameters = {
      type: 'QUERY_CH',
      id : "wan-usage-charts",
      queryName: 'REPORTS_GRAPHICAL_ALL_WAN_TIMESERIES',
      payload: {
          f1_k4id: vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
          startDate: newSummaryStartDate,
          endDate: newSummaryEndDate,
          interval: newInterval
      }    
    }
    dispatch(downloadAsCSV(params));
  }

  const [site, setSite] = useState<string>("");
  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
        if (authReducer.csvDownloadId === 'wan-usage-charts') {
            const [columns,  ...rows] = authReducer.csvDataDownloded;
            const filename = "wan-usage-charts-report";
            const csvContent = convertToCsv([columns, ...rows]);
            DownloadCSV(csvContent, {}, filename);
            return () => {
                dispatch(clearCsvDownload())
            }
        }
    }
  }, [authReducer.csvDataDownloded])

  const handlePlusClick = (k4Id) => {
    setSite(k4Id);
  }
  const handleMinusClick = (k4Id) => {
    setVesselWiseUsageData(prevData =>
      prevData.map(innerArray =>
          innerArray.map(vessel => {
              if (vessel.location_id === k4Id) {
                  const { network_usage, ...rest } = vessel;
                  return rest; 
              }
              return vessel;
          })
      )
    );
    setSite("");
  };
  useEffect(() => {
    if (site && selectCard === "TOTAL") {
        getWanUsageSingleSiteNetworkUsage({
            k4Id: site,
            start: newSummaryStartDate,
            end: newSummaryEndDate
        });
    }
    else if(site && selectCard === "VSAT") {
        getWanUsageSingleSiteVsatSelectionNetworkUsage({
            k4Id: site,
            start: newSummaryStartDate,
            end: newSummaryEndDate,
            wan_type: selectCard.toLowerCase()
        })
    }
    else if(site) {
        getWanUsageSingleSiteWanSelectionNetworkUsage({
            k4Id: site,
            start: newSummaryStartDate,
            end: newSummaryEndDate,
            wan_type: selectCard === "CELLULAR" ? "lte" : (selectCard === "ETHERNET" ? "wired" : selectCard.toLowerCase())
        })
    }
}, [site, newSummaryStartDate, newSummaryEndDate]);
useEffect(() => {
    if(!_.isEmpty(wanUsageReportSingleSiteNetworkUsage)) {
        const data = wanUsageReportSingleSiteNetworkUsage?.hasOwnProperty('data') ? wanUsageReportSingleSiteNetworkUsage?.data : {};
        const dataArr:any = [];
        data?.rows && data?.rows?.forEach((item, i) => {
            const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                acc[columItem] = item[i];
                return acc;
            }, {})
            dataArr.push(itemArr)
        })
        const updatedVesselWiseUsageData = vesselWiseUsageData.map(innerArray =>
          innerArray.map(vessel =>
              vessel.location_id === site
                  ? { ...vessel, network_usage: dataArr }
                  : vessel
          )
        );
        setVesselWiseUsageData(updatedVesselWiseUsageData);
    }
}, [wanUsageReportSingleSiteNetworkUsage])


useEffect(() => {
    if(!_.isEmpty(WanUsageSingleSiteWanSelectionNetworkUsage)) {
        const data = WanUsageSingleSiteWanSelectionNetworkUsage?.hasOwnProperty('data') ? WanUsageSingleSiteWanSelectionNetworkUsage?.data : {};
        const dataArr:any = [];
        data?.rows && data?.rows?.forEach((item, i) => {
            const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                acc[columItem] = item[i];
                return acc;
            }, {})
            dataArr.push(itemArr)
        })
        const updatedVesselWiseUsageData = vesselWiseUsageData.map(innerArray =>
          innerArray.map(vessel =>
              vessel.location_id === site
                  ? { ...vessel, network_usage: dataArr }
                  : vessel
          )
        );
        setVesselWiseUsageData(updatedVesselWiseUsageData);
    }
}, [WanUsageSingleSiteWanSelectionNetworkUsage])

useEffect(() => {
    if(!_.isEmpty(WanUsageSingleSiteVsatSelectionNetworkUsage)) {
        const data = WanUsageSingleSiteVsatSelectionNetworkUsage?.hasOwnProperty('data') ? WanUsageSingleSiteVsatSelectionNetworkUsage?.data : {};
        const dataArr:any = [];
        data?.rows && data?.rows?.forEach((item, i) => {
            const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                acc[columItem] = item[i];
                return acc;
            }, {})
            dataArr.push(itemArr)
        })
        const updatedVesselWiseUsageData = vesselWiseUsageData.map(innerArray =>
          innerArray.map(vessel =>
              vessel.location_id === site
                  ? { ...vessel, network_usage: dataArr }
                  : vessel
          )
        );
        setVesselWiseUsageData(updatedVesselWiseUsageData);
    }
}, [WanUsageSingleSiteVsatSelectionNetworkUsage])

  return (

    <div className="Form">
      <div>
        <div className="graphical-justyfy-space-between">

          <Grid container direction={'row'} spacing={1} className='tabs' >
            {counterCards.map((entry, idx) => {
              let label = entry.label;
              let value = entry.value;
              return (
                <Grid key={idx} spacing={1} item md='auto' className='top-widget-divs' >
                  <div className={`paper ${label === selectCard ? "selectedPaper" : "unSelectedPaper"}`}
                    onClick={() => {
                      handleCardSelection(label);
                    }}
                  >
                    <div className='d-flex'>
                      <div className='d-flex align-center'>
                        {label === selectCard ? <img src={ChartSelected} /> : <img src={ChartUnSelected} />}
                      </div>
                      <div >
                        <div className="text">
                          {value}
                        </div>
                        <div className="label">
                          {label}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
          
        { isChartDataAvailable && !_.isEmpty(vesselWiseUsageData) ? <div className="innerScrollGDU wanusageMarginReset">
            {!_.isEmpty(dateWiseUsageData) && (
              <div
                className='tabs shadow'
              >
                <Grid className="alignTopbarItemsCenter">
                  <div className="download-summary-btn marg-left-auto" onClick={() => handleDownloadCSV()}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid>
                <Grid className="wan-usage-legend-container">
                    {dateWiseUsageData?.map((legend, i) => (
                      <Grid className="wan-usage-legends">
                        <div style={{background:`${legend?.color}`}}></div>
                        <span className= {legend?.visible ? 'enable-usage-legends' : 'disable-wan-usage-legends'} onClick={() => handleLegendClick(legend?.name, legend[i]?.color, i)}>{legend?.name}</span>
                        <span className= {legend?.visible ? 'enable-usage-legends cursor' : 'disable-wan-usage-legends cursor'} >
                          { legends?.map((el) => {
                            if (el.wan_type == legend.name) {
                              return el.percentage < 1 ? `(${(el.percentage).toFixed(2)} %)` : (el.percentage == 100 || el.percentage == 0) ? `(${el.percentage} %)` : `(${(el.percentage).toFixed(2)} %)`
                            }
                          }) }
                        </span>
                      </Grid>
                    ))}
                </Grid>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            )}
            {selectCard && (
                <div className='tabs shadow vessel-chart-cont'>
                  <Grid>
                    <Grid className="vessel-chart-title">List of Sites</Grid>
                    <Grid>
                      <FormControl variant="standard" className='selectEntry-pie'>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={tableLimit}
                            onChange={handleChangeLimit}
                            >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={45}>45</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className='p-2 vessel-chart appUsageParent'>
                    <Grid item xs={12} sm={12} md={6} lg={4} >
                      <AppUsageCard appDownloadTableData={vesselWiseUsageData[0]} header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard} vessels={vessels} handlePlusClick={handlePlusClick} handleMinusClick={handleMinusClick} site={site} setSite={setSite}/>
                    </Grid>
                    {vessels.length > 1 ? <Grid item xs={12} sm={12} md={6} lg={4} >
                        <AppUsageCard appDownloadTableData={vesselWiseUsageData[1]} header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard} vessels={vessels} handlePlusClick={handlePlusClick} handleMinusClick={handleMinusClick} site={site} setSite={setSite}/>
                        </Grid> : selectCard === "TOTAL" ? <Grid item xs={12} sm={12} md={6} lg={4}>
                          <SingleSiteAllInterfaceUsage header="USAGE (GB)" tab={'vessel_chart'} />
                        </Grid> :
                        <Grid item xs={12} sm={12} md={6} lg={4} >
                          <SingleSiteInterfaceUsage header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard}/>
                      </Grid>
                    }
                    {vessels.length > 1 ? <Grid item xs={12} sm={12} md={6} lg={4} >
                        <AppUsageCard appDownloadTableData={vesselWiseUsageData[2]} header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard} vessels={vessels} handlePlusClick={handlePlusClick} handleMinusClick={handleMinusClick} site={site} setSite={setSite}/>
                        </Grid> : selectCard === "VSAT" ? <Grid item xs={12} sm={12} md={6} lg={4}>
                          <SingleSiteVsatNetworkUsage header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard}/>
                        </Grid> : selectCard === "TOTAL" ? <Grid item xs={12} sm={12} md={6} lg={4} >
                          <SingleSiteNetworkUsage header="USAGE (GB)" tab={'vessel_chart'} />
                        </Grid> : <Grid item xs={12} sm={12} md={6} lg={4}>
                          <SingleSiteWanNetworkUsage header="USAGE (GB)" tab={'vessel_chart'} selectCard={selectCard}/>
                        </Grid>}
                  </Grid>
                </div>
            )}
        </div> : <div className="wan-usage-no-data">No data available</div>}
      </div>
      { authReducer.graphicalDataUsageLoading ? (
          <CustomLoader
            showLoader={true}
            loadingText={ "Fetching data please wait..." }
          />
        ) : null
      }
    </div >
  )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  getVesselsListing: state.authReducer?.getVesselsListing,
  filteredVessels: state.authReducer?.filteredVessels,
  wanUsageReportSingleSiteNetworkUsage:state?.authReducer?.wanUsageReportSingleSiteNetworkUsage,
  WanUsageSingleSiteWanSelectionNetworkUsage: state?.authReducer?.WanUsageSingleSiteWanSelectionNetworkUsage,
  WanUsageSingleSiteVsatSelectionNetworkUsage: state?.authReducer?.WanUsageSingleSiteVsatSelectionNetworkUsage,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
});

export default withRouter(
  connect(mapStateToProps, {
    updatePermission,
    getLteProviders,
    getUsageVesselList,
    getGraphicalDataUsage,
    getWanUsageSingleSiteNetworkUsage, getWanUsageSingleSiteWanSelectionNetworkUsage, getWanUsageSingleSiteVsatSelectionNetworkUsage,
  })(GraphicalDataAnalysis)
);